import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import styled, { keyframes } from "styled-components";
import MainWrapper from "src/components/global/MainWrapper.js";
import main from "src/assets/styles/main.js";
import colors from "src/assets/styles/colors.js";
import distances from "src/assets/styles/distances.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import Body from "src/components/global/Typo/Body.js";
import Arrow from "src/assets/svg/arrow-drag.inline.svg";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import ButtonExternal from "src/components/global/ButtonExternal.js";

import clutchIcon from "src/assets/svg/clutch.svg";

const StyledMainWrapper = styled(MainWrapper)`
  margin-top: ${distances.sectionMarginBig}rem;
  margin-bottom: ${distances.sectionMarginBig}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin: 50rem 0;
  }
`;

const Wrapper = styled.div`
  background-color: ${(props) =>
    props.colorBg ? props.colorBg : "var(--rest)"};
  padding: 50rem;
  border-radius: ${main.radius}rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.laptop}) {
    padding: 15rem;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  opacity: 1;
  transform: translateY(0);
  height: 65rem;
  &.hide {
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    transform: translateY(10rem);
    opacity: 0;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    height: auto;
    align-items: flex-start;
  }
`;

const PersonWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const PersonImg = styled(Img)`
  height: 65rem;
  width: 65rem;
  border-radius: 65rem;
  margin-right: ${distances.gap}rem;
`;

const PersonInfoWrapper = styled.div``;

const PerosnName = styled.h3`
  font-size: ${typographySizes.sm}rem;
  line-height: ${typographySizes.smh};
  color: ${(props) => (props.colorText ? props.colorText : "var(--bg)")};

  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 20rem;
  }
`;

const CompanyName = styled.h4`
  font-size: ${typographySizes.s}rem;
  color: ${(props) => (props.colorText ? props.colorText : "var(--bg)")};
`;

const Line = styled.div`
  display: block;
  height: 1rem;
  border: 0;
  /* border-top: 1rem solid var(--bg); */
  /* margin: 1rem 0 0 0; */
  position: relative;
  padding: 0;
  z-index: 1;
  pointer-events: none;
  width: 100%;
  margin-top: ${distances.gap}rem;
  margin-bottom: ${distances.gap}rem;
  ::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% - 0rem);
    height: 1rem;
    background-color: ${(props) =>
      props.colorText ? props.colorText : "var(--bg)"};
    transition: all 0.25s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.hide::before {
    width: 0%;
    transition: all 0.25s cubic-bezier(0.65, 0, 0.35, 1);
  }
  ::after {
    content: "";
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    width: 0%;
    height: 1rem;
    background-color: ${(props) =>
      props.colorText ? props.colorText : "var(--bg)"};
    transition: all 0.25s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.hide::after {
    width: 100%;
    transition: all 0.25s 0.25s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const Logo = styled(Img)`
  width: ${(props) => props.w}rem;
  height: ${(props) => props.h}rem;
  max-height: 65rem !important;
  background-color: ${(props) =>
    props.colorBg ? props.colorBg : "var(--rest)"};
  max-width: 160rem !important;

  img {
    object-fit: contain !important;
    mix-blend-mode: difference;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

const MidWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  opacity: 1;
  transform: translateY(0);
  &.hide {
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
    transform: translateY(10rem);
    opacity: 0;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    flex-direction: column;
  }
`;

const NewMidWrapper = styled(MidWrapper)`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  top: 0;
  left: 0;
  width: calc((100vw - 100rem - ${distances.pageMarginBig * 2}rem));
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc((100vw - 60rem));
    left: 30rem;
  }
`;

const FeaturedText = styled.h3`
  font-size: ${typographySizes.m}rem;
  color: ${(props) => (props.colorText ? props.colorText : "var(--bg)")};

  line-height: ${typographySizes.mh};
  width: 40%;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    margin-bottom: 20rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.sm}rem;
    line-height: ${typographySizes.smh};
  }
`;

const StyledBody = styled(Body)`
  column-count: 1;
  width: ${(props) => (props.col2 ? "100" : "50%")};
  color: ${(props) => (props.colorText ? props.colorText : "var(--bg)")};
  width: calc(
    (100vw - 100rem - ${distances.pageMarginBig * 2}rem) * 0.565 / 2 -
      ${distances.gap}rem
  );
  margin-right: calc(
    (100vw - 100rem - ${distances.pageMarginBig * 2}rem) * 0.565 / 2 +
      ${distances.gap}rem
  );
  column-gap: ${distances.gap}rem;
  &.col2 {
    column-count: 2;
    width: calc((100vw - 100rem - ${distances.pageMarginBig * 2}rem) * 0.565);
    margin-right: 0;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    &.col2 {
      width: 100%;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    column-count: 1;
    &.col2 {
      column-count: 1;
    }
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  border-radius: ${main.radius}rem;
  overflow: hidden;
  /* background-color: red; */
  width: calc((100vw - 100rem - ${distances.pageMarginBig * 2}rem) * 0.565);
  height: calc(
    ((100vw - 100rem - ${distances.pageMarginBig * 2}rem) * 0.565) * 0.56
  );
  @media (max-width: ${mediaQuery.laptop}) {
    width: auto;
    height: auto;
  }
`;
const StyledVideo = styled.video`
  /* position: absolute;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  width: 100%;
  /* height: 101%; */
`;

const Controls = styled.div`
  margin-top: 30rem;
  display: flex;
  @media (max-width: ${mediaQuery.laptop}) {
    margin-top: 50rem;
  }
`;

const PaginationWrapper = styled.span`
  color: ${(props) => (props.colorText ? props.colorText : "var(--bg)")};
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30rem;
  /* margin-top: 15rem; */
`;

const StyledArrow = styled(Arrow)`
  width: 13rem;
  cursor: pointer;
  #arr {
    fill: var(--bg);
  }
  #arrow {
    fill: var(--bg);
  }
`;

const ArrowLeft = styled(StyledArrow)`
  transform: rotate(-180deg);
  position: relative;
  padding-left: 7.5rem;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &:hover {
    transform: translateY(-3rem) rotate(-180deg);
  }
`;

const ArrowRight = styled(StyledArrow)`
  padding-left: 7.5rem;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &:hover {
    transform: translateY(-3rem);
  }
`;

const ClutchWrapper = styled.div`
  margin-left: calc(((100vw - 100rem) - 100rem) * 0.435);
  margin-top: 40rem;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  opacity: 1;
  transform: translateY(0);
  @media (max-width: ${mediaQuery.laptop}) {
    margin-left: 0;
  }
  &.hide {
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
    transform: translateY(10rem);
    opacity: 0;
  }
  ${ButtonExternal} {
  }
  img {
    width: 36rem;
    overflow: hidden;
    border-radius: 100%;
    margin-right: 20rem;
  }
`;

const StyleButtonExternal = styled(ButtonExternal)`
  color: ${(props) =>
    props.colorText ? props.colorText : "var(--bg)"}!important;
  align-self: initial;
  ::after {
    background-color: ${(props) =>
      props.colorText ? props.colorText : "var(--bg)"};
  }
  ::before {
    background-color: ${(props) =>
      props.colorText ? props.colorText : "var(--bg)"};
  }
`;

const Testimonial = ({
  testimonials,
  testimonial,
  colorBg,
  colorText,
  lang,
}) => {
  const [indexNum, setIndexNum] = useState(0);
  const [indexNumBlock, setIndexNumBlock] = useState(0);
  const [newH, setNewH] = useState(0);
  const [hideContent, setHideContent] = useState(false);
  const newContent = useRef();

  const testimonialDataBlock = testimonials
    ? testimonials.edges[indexNumBlock].node
    : testimonial;
  const testimonialData = testimonials
    ? testimonials.edges[indexNum].node
    : testimonial;

  const [videoSource, setVideoSource] = useState(
    testimonialDataBlock.Testimonial[0].strapi_component === "testimonial.video"
      ? testimonialDataBlock.Testimonial[0].Video.localFile.publicURL
      : ""
  );

  useEffect(() => {
    if (
      testimonialDataBlock.Testimonial[0].strapi_component ===
      "testimonial.video"
    ) {
      setVideoSource(
        testimonialDataBlock.Testimonial[0].Video.localFile.publicURL
      );
    }
    let height = newContent.current.offsetHeight;
    setNewH(height);
  });

  // useEffect(() => {
  //   if (
  //     testimonialDataBlock.Testimonial[0].strapi_component ===
  //     "testimonial.video"
  //   ) {
  //     setVideoSource(
  //       testimonialDataBlock.Testimonial[0].Video.localFile.publicURL
  //     );
  //   }
  // }, [testimonialDataBlock]);

  useEffect(() => {
    if (
      testimonialDataBlock.Testimonial[0].strapi_component ===
      "testimonial.video"
    ) {
      setVideoSource(
        testimonialDataBlock.Testimonial[0].Video.localFile.publicURL
      );
    }
  }, [hideContent]);

  const nexTest = () => {
    let height = newContent.current.offsetHeight;
    setNewH(height);
    setHideContent(true);
    console.log("next");
    if (testimonials.edges.length > 0) {
      if (indexNum == testimonials.edges.length - 1) {
        setIndexNum(0);
        setTimeout(function () {
          setIndexNumBlock(0);
          setHideContent(false);
        }, 500);
      } else {
        setIndexNum(indexNum + 1);
        setTimeout(function () {
          setIndexNumBlock(indexNumBlock + 1);
          setHideContent(false);
        }, 700);
      }
    }
  };

  const prevTest = () => {
    let height = newContent.current.offsetHeight;
    setNewH(height);
    setHideContent(true);

    if (testimonials.edges.length > 0) {
      if (indexNum == 0) {
        setIndexNum(testimonials.edges.length - 1);
        setTimeout(function () {
          setIndexNumBlock(testimonials.edges.length - 1);
          setHideContent(false);
        }, 500);
      } else {
        setIndexNum(indexNum - 1);
        setTimeout(function () {
          setIndexNumBlock(indexNumBlock - 1);
          setHideContent(false);
        }, 700);
      }
    }
  };

  return (
    <StyledMainWrapper>
      <Wrapper colorBg={colorBg}>
        <TopWrapper className={hideContent && "hide"}>
          <PersonWrapper>
            {testimonialDataBlock.Person_img && (
              <PersonImg
                loading="eager"
                fluid={
                  testimonialDataBlock.Person_img.localFile.childImageSharp
                    .fluid
                }
              />
            )}

            <PersonInfoWrapper>
              <PerosnName colorText={colorText}>
                {testimonialDataBlock.Person_name}
              </PerosnName>
              <CompanyName colorText={colorText}>
                {testimonialDataBlock.Company_name}
              </CompanyName>
            </PersonInfoWrapper>
          </PersonWrapper>
          <Logo
            colorBg={colorBg}
            h={testimonialDataBlock.Logo.height}
            w={testimonialDataBlock.Logo.width}
            loading="eager"
            fluid={testimonialDataBlock.Logo.localFile.childImageSharp.fluid}
            objectPosition="50% 50%"
            alt=""
          />
        </TopWrapper>
        <Line colorText={colorText} className={hideContent && "hide"} />
        <MidWrapper
          className={hideContent && "hide"}
          style={{
            height: newH + "rem",
          }}
        >
          <FeaturedText colorText={colorText}>
            {testimonialDataBlock.Featured_text}
          </FeaturedText>

          {testimonialDataBlock.Testimonial[0].strapi_component ==
            "testimonial.text" && (
            <StyledBody
              colorText={colorText}
              className={
                testimonialDataBlock.Testimonial[0].Testimonial.data.Testimonial
                  .length > 200 && "col2"
              }
            >
              {testimonialDataBlock.Testimonial[0].Testimonial.data.Testimonial}
            </StyledBody>
          )}
          {testimonialDataBlock.Testimonial[0].strapi_component ==
            "testimonial.video" &&
            !hideContent && (
              <VideoWrapper>
                <StyledVideo controls>
                  <source src={`${videoSource}#t=0.00`} type="video/mp4"></source>
                </StyledVideo>
              </VideoWrapper>
            )}
        </MidWrapper>
        {/* <Line colorText={colorText} className={hideContent && "hide"} /> */}

        <NewMidWrapper ref={newContent}>
          <FeaturedText>{testimonialData.Featured_text}</FeaturedText>
          {testimonialDataBlock.Testimonial[0].strapi_component ==
            "testimonial.text" && (
            <StyledBody
              className={
                testimonialDataBlock.Testimonial[0].Testimonial.data.Testimonial
                  .length > 200 && "col2"
              }
            >
              {testimonialDataBlock.Testimonial[0].Testimonial.data.Testimonial}
            </StyledBody>
          )}
          {testimonialDataBlock.Testimonial[0].strapi_component ==
            "testimonial.video" && (
            <VideoWrapper>
              <StyledVideo controls>
                <source src={videoSource} type="video/mp4"></source>
              </StyledVideo>
            </VideoWrapper>
          )}
        </NewMidWrapper>
        {testimonialDataBlock.Clutch && (
          <ClutchWrapper className={hideContent && "hide"}>
            <img src={clutchIcon} />
            <StyleButtonExternal
              href={testimonialDataBlock.Clutch}
              target="_blank"
              colorText={colorText}
            >
              {lang !== "en"
                ? "zobacz pełną opinie na clutch.co"
                : "see full review at clutch.co"}
            </StyleButtonExternal>
          </ClutchWrapper>
        )}

        {testimonials && (
          <Controls>
            <PaginationWrapper colorText={colorText}>
              {indexNum + 1} / {testimonials.edges.length}
            </PaginationWrapper>

            <ArrowWrapper>
              <ArrowLeft onClick={prevTest} />
              <ArrowRight onClick={nexTest} />
            </ArrowWrapper>
          </Controls>
        )}
      </Wrapper>
    </StyledMainWrapper>
  );
};

export default Testimonial;
