import React from "react";
import Img from "gatsby-image";
import SmallTitle from "src/components/global/Typo/SmallTitle.js";

import styled from "styled-components";
import SectionMargin from "src/components/project/SectionMargin.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import Line from "src/components/global/Line.js";
import main from "src/assets/styles/main.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import colors from "src/assets/styles/colors.js";
import Body from "src/components/global/Typo/Body.js";
// markup

const StyledSectionMargin = styled(SectionMargin)`
  margin: 100rem 0;
  @media (max-width: ${mediaQuery.tablet}) {
    margin: 50rem 0;
  }
`;

const StyledMainWrapper = styled(MainWrapper)`
  display: flex;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
  }
`;

const LeftCol = styled.div`
  width: 37.5%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-bottom: 50rem;
    &.hidemobile {
      display: none;
    }
  }
`;

const RightCol = styled.div`
  width: 56.25%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const InfoCol = styled.div`
  width: 44.44%;
  :last-of-type {
    margin-bottom: 0;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    margin-bottom: 50rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
  }
`;

const StyledTitle = styled(SmallTitle)`
  color: ${(props) => props.color};
`;

const ColTitle = styled(Body)`
  color: ${(props) => props.color};
  margin-bottom: 5rem;
`;

const ColBody = styled(Body)`
  color: ${(props) => props.color};
  margin-top: 20rem;
`;

const AwardsWrapper = styled.div`
  width: 100%;
  margin-top: ${(props) => (props.alone ? "50rem" : "0rem")};
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 0;
    &.awards {
      margin-top: 50rem;
    }
  }
`;

const Award = styled.a`
  width: calc(33.33% - 13.33rem);
  display: inline-block;
  border-radius: ${main.radius}rem;
  background-color: var(--rest);
  margin-top: 20rem;
  margin-right: 20rem;
  overflow: hidden;
  :nth-of-type(3n) {
    margin-right: 0;
  }

  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(50% - 10rem);
    margin-right: 20rem;
    :nth-of-type(3n) {
      margin-right: 20rem;
    }
    :nth-last-of-type(odd) {
      margin-right: 0;
    }
  }
`;

const About = ({ about, TextDark, lang }) => {
  return (
    <StyledSectionMargin>
      {console.log(about)}
      <StyledMainWrapper>
        <LeftCol className={!about.Title_main && "hidemobile"}>
          <StyledTitle color={TextDark ? colors.dark : colors.white}>
            {about.Title_main}
          </StyledTitle>
        </LeftCol>
        <RightCol>
          {about.Title_first_col && (
            <InfoCol>
              <ColTitle color={TextDark ? colors.dark : colors.white}>
                {about.Title_first_col}
              </ColTitle>
              <Line color={TextDark ? colors.dark : colors.white} />
              <ColBody color={TextDark ? colors.dark : colors.white}>
                {about.Description_first_col}
              </ColBody>
            </InfoCol>
          )}
          {about.Title_second_col && (
            <InfoCol>
              <ColTitle color={TextDark ? colors.dark : colors.white}>
                {about.Title_second_col}
              </ColTitle>
              <Line color={TextDark ? colors.dark : colors.white} />
              <ColBody color={TextDark ? colors.dark : colors.white}>
                {about.Description_second_col}
              </ColBody>
            </InfoCol>
          )}
          {about.Awards.length > 0 && (
            <AwardsWrapper
              alone={about.Title_first_col && about.Title_second_col}
            >
              <ColTitle color={TextDark ? colors.dark : colors.white}>
                {lang === "pl" ? "Nagrody i wyróżnienia" : "Awards"}
              </ColTitle>
              <Line
                color={TextDark ? colors.dark : colors.white}
                width={about.Awards.length > 1 ? "100%" : "44.44%"}
              />
              {about.Awards.map((pos) => (
                <Award href={pos.Link} target="_blank">
                  <Img
                    loading="eager"
                    fluid={pos.Project_graphic.localFile.childImageSharp.fluid}
                  />
                </Award>
              ))}
            </AwardsWrapper>
          )}
          {about.publications.length > 0 && (
            <AwardsWrapper
              className={about.Awards.length > 0 && "awards"}
              alone={
                (about.Title_first_col && about.Title_second_col) ||
                about.Awards.length > 0
              }
            >
              <ColTitle color={TextDark ? colors.dark : colors.white}>
                {about.Publications_title}
              </ColTitle>
              <Line
                color={TextDark ? colors.dark : colors.white}
                width={about.publications.length > 1 ? "100%" : "44.44%"}
              />
              {about.publications.map((pos) => (
                <Award href={pos.Publications_url} target="_blank">
                  <Img
                    loading="eager"
                    fluid={pos.Publications_img.localFile.childImageSharp.fluid}
                  />
                </Award>
              ))}
            </AwardsWrapper>
          )}
        </RightCol>
      </StyledMainWrapper>
    </StyledSectionMargin>
  );
};

export default About;
