import React, { useRef, useState, useEffect } from "react";
import Img from "gatsby-image";
import SmallTitle from "src/components/global/Typo/SmallTitle.js";

import styled from "styled-components";
import SectionMargin from "src/components/project/SectionMargin.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import Line from "src/components/global/Line.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import typographySizes from "src/assets/styles/typographySizes.js";

import colors from "src/assets/styles/colors.js";
import distances from "src/assets/styles/distances.js";

import Body from "src/components/global/Typo/Body.js";

// markup

const StyledSectionMargin = styled(SectionMargin)`
  margin: 100rem 0;
  @media (max-width: ${mediaQuery.tablet}) {
    margin: 50rem 0;
  }
`;

const TextWrapper = styled.div`
  width: 68.75%;
  margin-left: 31.25%;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    margin-left: 0;
  }
`;

const StyledTitle = styled(SmallTitle)`
  margin-top: 40rem;
  color: ${(props) => props.color};
`;

const StyledBody = styled(Body)`
  width: 60%;
  margin-left: 40%;
  color: ${(props) => props.color};
  margin-top: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
  }
`;

const Text = ({ text, TextDark }) => {
  return (
    <StyledSectionMargin>
      <MainWrapper>
        <TextWrapper>
          <Line color={TextDark ? colors.dark : colors.white} />
          <StyledTitle color={TextDark ? colors.dark : colors.white}>
            {text.Big_text}
          </StyledTitle>
          <StyledBody color={TextDark ? colors.dark : colors.white}>
            {text.Small_text}
          </StyledBody>
        </TextWrapper>
      </MainWrapper>
    </StyledSectionMargin>
  );
};

export default Text;
