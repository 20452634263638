import React from "react";

import styled from "styled-components";
import Markdown from "markdown-to-jsx";

import colors from "src/assets/styles/colors.js";
import distances from "src/assets/styles/distances.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import Line from "src/components/global/Line.js";
import Body from "src/components/global/Typo/Body.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import Animate from "src/components/global/Typo/Animate.js";
import AnimateBody from "src/components/global/Typo/AnimateBody.js";
// markup

const TopInfo = styled.div`
  padding-top: ${distances.topMargin}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: ${distances.topMarginMobile}rem;
  }
`;

const Title = styled.h1`
  font-size: ${typographySizes.xl}rem;
  /* font-size: 15vw; */
  width: 75%;
  .markdown {
    p {
      margin-bottom: 20rem;
    }
  }
  line-height: ${typographySizes.xlh};
  margin-bottom: 20rem;
  color: ${(props) => (props.color ? props.color : colors.white)};
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.l}rem;
  }
`;

const Descritpion = styled.h2`
  font-size: ${typographySizes.l}rem;
  color: ${(props) => (props.color ? props.color : colors.white)};
  width: 62.5%;
  line-height: ${typographySizes.lh};
  margin: 20rem 0;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.sm}rem;
  }
`;

const CategoriesWrapper = styled.div`
  margin: 20rem 0;
  ${Body} {
    text-transform: lowercase;
    color: ${(props) => (props.color ? props.color : colors.white)};
  }
`;

const Category = styled(Body)`
  div {
    display: inline-block;
  }
`;

const HeaderTop = ({ data }) => {
  return (
    <TopInfo>
      <Title
        color={data.strapiProject.Text_dark ? colors.dark : colors.white}
        className="intro-text"
      >
        <Animate text={data.strapiProject.Title} />
      </Title>
      <Line
        className={"animate delay"}
        color={data.strapiProject.Text_dark ? colors.dark : colors.white}
      />
      <Descritpion
        className="intro-text"
        color={data.strapiProject.Text_dark ? colors.dark : colors.white}
      >
        <AnimateBody text={data.strapiProject.Intro_text} />
      </Descritpion>
      <Line
        className={"animate delay"}
        color={data.strapiProject.Text_dark ? colors.dark : colors.white}
      />
      <CategoriesWrapper
        color={data.strapiProject.Text_dark ? colors.dark : colors.white}
      >
        <Category className="intro-text">
          {data.strapiProject.categories.map((category, index) => (
            <>
              <AnimateBody text={category.Name} />
              <AnimateBody
                text={
                  index === data.strapiProject.categories.length - 1 ? "" : "|"
                }
              />
            </>
          ))}
        </Category>
      </CategoriesWrapper>{" "}
      <Line
        className={"animate delay"}
        color={data.strapiProject.Text_dark ? colors.dark : colors.white}
      />
    </TopInfo>
  );
};

export default HeaderTop;
