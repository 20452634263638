import React, { useRef, useState, useEffect } from "react";
import Img from "gatsby-image";
import SmallTitle from "src/components/global/Typo/SmallTitle.js";

import styled from "styled-components";
import SectionMargin from "src/components/project/SectionMargin.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import Line from "src/components/global/Line.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import typographySizes from "src/assets/styles/typographySizes.js";

import colors from "src/assets/styles/colors.js";
import distances from "src/assets/styles/distances.js";

import Body from "src/components/global/Typo/Body.js";
import main from "../../assets/styles/main";

const Wrapper = styled.a`
  height: 80px;
  margin-top: -80rem;
  width: 280px;
  background-color: ${(props) => props.color};
  display: block;
  position: sticky;
  top: 100vh;
  transform: translateY(0);
  margin-left: auto;
  margin-right: ${distances.pageMarginBig / 2}rem;
  z-index: 999;
  border-radius: ${main.radius}rem;
  display: flex;
  text-decoration: none;
  justify-content: space-between;
  transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.scrolled {
    transform: translateY(calc(-100% - ${distances.pageMarginBig / 2}rem));
    transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.bottomHide {
    opacity: 0;
  }
  :hover {
    width: 300rem;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10rem;
  hr {
    width: 100%;
    border-top: 1px solid #e8e8e8;
  }
  &.dark {
    hr {
      border-top: 1px solid #444444;
    }
  }
`;

const Title = styled.span`
  font-size: ${typographySizes.s}rem;
  line-height: 1.1;
  color: ${(props) => props.color};
`;

const Link = styled.span`
  font-size: ${typographySizes.ss}rem;
  color: ${(props) => props.color};
`;

const ImgWrapper = styled.div`
  background-color: #e8e8e8;
  padding: 5rem;
  width: 150rem;
  box-sizing: border-box;
  border-radius: ${main.radius}rem;
  div {
    height: 100%;
  }
  &.dark {
    background-color: #444444;
  }
`;

const StyledImg = styled(Img)`
  border-radius: 4rem;
`;

const WwwLabel = ({ text, url, img, Text_dark, lang }) => {
  const [scrolled, setScrolled] = useState(false);
  const isBrowser = typeof window !== "undefined";
  const elementRef = useRef(null);
  const [distanceFromTop, setDistanceFromTop] = useState(0);
  const [bottomHide, setBottomHide] = useState(false);
  let windowHeight = 0;
  if (isBrowser) {
    windowHeight = window.innerHeight;
  }
  useEffect(() => {
    if (isBrowser) {
      window.onscroll = function (e) {
        let scrollTop =
          window.pageYOffset ||
          (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop;

        scrollTop > 700 ? setScrolled(true) : setScrolled(false);
      };
    }
  }, []);

  const calculateDistanceFromTop = () => {
    if (elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();
      const distance = rect.top;

      setDistanceFromTop(distance);

      if (distance + 105 < windowHeight) {
        setBottomHide(true);
      } else {
        setBottomHide(false);
      }
    }
  };

  useEffect(() => {
    if (isBrowser) {
      calculateDistanceFromTop(); // Initial calculation

      // Attach an event listener for scroll events
      window.addEventListener("scroll", calculateDistanceFromTop);

      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener("scroll", calculateDistanceFromTop);
      };
    }
  }, []);
  return (
    <Wrapper
      ref={elementRef}
      className={`${scrolled && "scrolled"} ${bottomHide && "bottomHide"}`}
      color={Text_dark ? colors.dark : colors.white}
      href={url}
      target="_blank"
    >
      <InfoCol className={Text_dark && "dark"}>
        <Title color={Text_dark ? colors.white : colors.dark}>
          {lang === "pl" ? (
            <>
              Zobacz stronę <br /> projektu
            </>
          ) : (
            <>
              See project <br /> website
            </>
          )}
        </Title>
        <hr />
        <Link color={Text_dark ? colors.white : colors.dark}>{text}</Link>
      </InfoCol>
      <ImgWrapper className={Text_dark && "dark"}>
        <StyledImg fluid={img.localFile.childImageSharp.fluid} />
      </ImgWrapper>
    </Wrapper>
  );
};

export default WwwLabel;
