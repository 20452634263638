import React, { useRef, useState, useEffect } from "react";
import Img from "gatsby-image";
import SmallTitle from "src/components/global/Typo/SmallTitle.js";

import styled from "styled-components";
import SectionMargin from "src/components/project/SectionMargin.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import Line from "src/components/global/Line.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import typographySizes from "src/assets/styles/typographySizes.js";

import colors from "src/assets/styles/colors.js";
import distances from "src/assets/styles/distances.js";

import Body from "src/components/global/Typo/Body.js";

// markup

const StyledSectionMargin = styled(SectionMargin)`
  margin-top: 100rem;
  margin-bottom: 100rem;
  &.top {
    margin-top: 40rem;
  }
  ${MainWrapper} {
    display: flex;
    &.center {
      justify-content: center;
    }
    &.left {
      justify-content: flex-start;
    }
    &.right {
      justify-content: flex-end;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 50rem;
    margin-bottom: 50rem;
  }
`;

const ButtonWrapper = styled.a`
  border: solid 1rem;
  border-color: ${(props) => props.color};
  font-size: ${typographySizes.m}rem;
  text-align: center;
  color: ${(props) => props.color};
  align-self: center;
  display: inline-block;
  padding: 0 30rem;

  cursor: pointer;
  /* margin-left: 50rem; */
  padding: 0 30rem;

  border-radius: 60rem;
  height: 51rem;
  overflow: hidden;
  text-decoration: none;
  span {
    font-size: inherit;
    color: inherit;
    display: block;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    text-decoration: none;
  }
  :hover {
    span {
      transform: translateY(-100%);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }

  &.active {
    background: var(--rest);
    color: var(--bg);
    border: solid 1rem var(--bg);
  }

  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}rem;
    padding: 5rem 15rem;

    height: 18rem;
  }
`;

const LinkUrl = ({ text, url, TextDark, pos, index }) => {
  return (
    <StyledSectionMargin
      className={index === 0 && "top"}
      color={TextDark ? colors.dark : colors.white}
    >
      <MainWrapper className={pos}>
        <ButtonWrapper
          color={TextDark ? colors.dark : colors.white}
          href={url}
          target="_blank"
        >
          <span>{text}</span>
          <span>{text}</span>
        </ButtonWrapper>
      </MainWrapper>
    </StyledSectionMargin>
  );
};

export default LinkUrl;
