import styled from "styled-components";
import mediaQuery from "src/assets/styles/mediaQuery.js";

const SectionMargin = styled.div`
  margin: 50rem 0;
  @media (max-width: ${mediaQuery.tablet}) {
    margin: 25rem 0;
  }
`;

export default SectionMargin;
