import React from "react";

import styled from "styled-components";
import SectionMargin from "src/components/project/SectionMargin.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import Line from "src/components/global/Line.js";

import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import Body from "src/components/global/Typo/Body.js";

// markup

const StyledSectionMargin = styled(SectionMargin)`
  margin: 100rem 0;
  @media (max-width: ${mediaQuery.tablet}) {
    margin: 50rem 0;
  }
`;

const StyledBody = styled(Body)`
  color: ${(props) => props.color};
  :last-of-type {
    max-width: 50%;
    text-align: right;
  }
`;

const PosRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25rem 0;
`;

const Credentials = ({ positions, TextDark }) => {
  return (
    <StyledSectionMargin>
      <MainWrapper>
        <Line color={TextDark ? colors.dark : colors.white} />

        {positions.map((pos) => (
          <>
            <PosRow>
              <StyledBody color={TextDark ? colors.dark : colors.white}>
                {pos.Position_name}
              </StyledBody>
              <StyledBody color={TextDark ? colors.dark : colors.white}>
                {pos.Name}
              </StyledBody>
            </PosRow>
            <Line color={TextDark ? colors.dark : colors.white} />
          </>
        ))}
      </MainWrapper>
    </StyledSectionMargin>
  );
};

export default Credentials;
